
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(80);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        $('[data-component="instagram"]').each(function(index, el) {
            var nameInsta     = $(this).data('name');
            var qtd           = $(this).data('qtd');
            var instagramJson = "https://www.instagram.com/"+nameInsta+"/?__a=1";
            $.ajax({
                url: instagramJson,
                type: 'GET',
                dataType: 'json',
            })
            .done(function( data ) {
                $.each( data.graphql.user.edge_owner_to_timeline_media.edges, function( i, item ) {
                    $( "<a class='item' target='_blank'>" ).attr( "href", "https://www.instagram.com/p/"+item.node.shortcode+"/" ).attr( "style", "background-image: url("+item.node.display_url+");" ).appendTo( "[data-component=instagram]" );
                    if ( i === (qtd - 1) ) {
                        return false;
                    }
                });
            });
        });
        $('[data-component=depoimentos]').each(function(index, el) {
            $(this).addClass('owl-carousel');
            $(this).owlCarousel({
                loop            : true,
                items           : 3,
                autoplay        : true,
                autoplayTimeout : 5000,
                nav             : true,
                animateOut      : 'fadeOut',
                navText         : ['<i class="icon-left"></i>', '<i class="icon-right"></i>'],
                dots            : false,
                margin          : 60,
                responsive      : {
                    0:{items : 1},
                    600:{items : 2},
                    993:{items : 3}
                },

            });
        });

        // Form tabs
        $('.tab').click(function(event) {  
            event.preventDefault();
            target = $(this).attr('href');

            $('.tab').removeClass('_active');
            $('.tab[href="'+target+'"]').addClass('_active');


            $('.tab-item').removeClass('_active');
            $(target).addClass('_active');
        });

        // Scroll top ao inicializar
        if ('scrollRestoration' in history) {
            history.scrollRestoration = 'manual';
        }
        
        window.scrollTo(0,0);

        // Paginacao
        var countPag = 6; // qtd de inicio
        var total    = $('.spawn .box').length;
        $('.spawn .box:gt(5)').hide();

        if (countPag >= total) {
            $('.Posts .click').hide();
        }

        $('.Posts .click').click(function(event) {
            event.preventDefault();
            
            // de quanto em quanto
            countPag = countPag + 3; 

            if (countPag >= total) {
                $(this).hide();
            }

            $('.spawn .box:lt(' + countPag +')').show();
        });
    });
});